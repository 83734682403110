import React from "react";
import { Link } from 'react-router-dom';
import YoutubeEmbed from "../components/YoutubeEmbed";

const SuperSandPage = () => {
    return (
        <div>
            <div className="center">
                <div className="fiery">
                    <div className="littlenefarious">
                        SuperSand
                    </div>
                </div>
                <div className="smallheading">
                    <Link to="https://github.com/Schmitt-Very-Cool/SuperSand">GitHub</Link>
                    <Link to="https://the-nefarious-machine.itch.io/supersand" style={{ marginLeft: "10px" }}>Itch.io</Link>
                </div>
                <br/>
                <YoutubeEmbed embedId="GHPuJmhAPXE"/>
                <br/>
                <div className="subheading">Description</div>
                SuperSand is a falling sand game that utilizes cellular automata to simulate 2d physics. I wrote the code in C++ and used SFML for the GUI. The game is still under production, so it only simulates physical movement, not heat or chemical reactions. In the future I plan to add those features and more. 
                <br/>
                <br/>
                <div className="subheading">Gallery</div>
                <div className="gallery">
                    <img className="gallery-item" src="assets/supersand/bricks.jpg"/>
                    <img className="gallery-item" src="assets/supersand/menu.jpg"/>
                    <img className="gallery-item" src="assets/supersand/layers.jpg"/>
                </div>
            </div>
        </div>
    );
}

export default SuperSandPage;